<template>
  <div>
    <div class="row mx-0 pt-3">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Job type </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="job-function"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.job_type }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="deleteButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0 mt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm"
            class="card-label font-weight-bolder Main_Blue"
            >Add new Job type
          </span>
          <span
            v-if="updateForm"
            class="card-label font-weight-bolder Main_Blue"
            >Update Job type
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <!-- v-if="createForm" -->
          <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="jobType"
              :rules="nameRules"
              label="Job type name"
              required
            ></v-text-field>
            <button
              type="button"
              @click="createJobType"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
          </v-form>
          <!-- update form start -->
          <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="jobType"
              :rules="nameRules"
              label="Job type name"
              required
            ></v-text-field>
            <button
              type="button"
              @click="updateJobType"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-form>
          <!-- update form end -->
        </div>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: true,
      updateForm: false,
      jobType: "",
      job_function_id: null,
      nameRules: [
        (v) => !!v || "Job type is required",
        (v) =>
          (v && v.length <= 20) || "Job type must be less than 20 characters",
      ],
      headers: [
        { text: "Job Type", value: "job_type" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    cancel() {
      this.createForm = true;
      this.updateForm = false;
      this.jobType = "";
    },
    createFormEnable() {
      this.jobType = "";
      this.createForm = true;
      this.updateForm = false;
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-type`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createJobType() {
      if (this.jobType == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/job-type`, {
          job_type: this.jobType,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.jobType = "";
          Swal.fire({
            title: "",
            text: "Job type added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateJobType() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/job-type/${this.job_function_id}`, {
          job_type: this.jobType,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.jobType = "";
          this.job_function_id = "";
          Swal.fire({
            title: "",
            text: "Job type updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/job-type/${item.id}`, {
          job_type: item.job_type,
          status: !item.status,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.jobType = "";
          this.job_function_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.jobType = item.job_type;
      this.job_function_id = item.id;
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/job-type/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Job type deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
